export const NavbarLinks = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Courses",
    path: "/course",
  },
  {
    title: "Project",
    path: "/project",
  },
  {
    title: "About Us",
    path: "/about",
  },
  {
    title: "Contact Us",
    path: "/contact",
  },
  {
    title: "Rate Us",
    path: "/rateus",
  },
];
