// import { useState } from "react";
// import { toast } from "react-hot-toast";
// import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";

// import { sendOtp } from "../../../services/operations/authAPI";
// import { setSignupData } from "../../../slices/authSlice";
// import { ACCOUNT_TYPE } from "../../../utils/constants";
// import Tab from "../../common/Tab";

// import { FaUser, FaLock } from "react-icons/fa";
// import { SiGmail } from "react-icons/si";

// function SignupForm() {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   // student or instructor
//   const [accountType, setAccountType] = useState(ACCOUNT_TYPE.STUDENT);

//   const [formData, setFormData] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     password: "",
//     confirmPassword: "",
//   });

//   const [showPassword, setShowPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);

//   const { firstName, lastName, email, password, confirmPassword } = formData;

//   // Handle input fields, when some value changes
//   const handleOnChange = (e) => {
//     setFormData((prevData) => ({
//       ...prevData,
//       [e.target.name]: e.target.value,
//     }));
//   };

//   // Password validation function
//   const isPasswordValid = (password) => {
//     const minLength = 8;
//     const hasUpperCase = /[A-Z]/.test(password);
//     const hasLowerCase = /[a-z]/.test(password);
//     const hasNumber = /\d/.test(password);
//     const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>_]/.test(password);

//     return (
//       password.length >= minLength &&
//       hasUpperCase &&
//       hasLowerCase &&
//       hasNumber &&
//       hasSpecialChar
//     );
//   };

//   // Handle Form Submission
//   const handleOnSubmit = (e) => {
//     e.preventDefault();

//     if (password !== confirmPassword) {
//       toast.error("Passwords do not match");
//       return;
//     }

//     if (!isPasswordValid(password)) {
//       toast.error(
//         "Password must be at least 8 characters long and include uppercase, lowercase, number, and special character"
//       );
//       return;
//     }

//     const signupData = {
//       ...formData,
//       accountType,
//     };

//     // Setting signup data to state
//     // To be used after otp verification
//     dispatch(setSignupData(signupData));
//     // Send OTP to user for verification
//     dispatch(sendOtp(formData.email, navigate));

//     // Reset
//     setFormData({
//       firstName: "",
//       lastName: "",
//       email: "",
//       password: "",
//       confirmPassword: "",
//     });
//     setAccountType(ACCOUNT_TYPE.STUDENT);
//   };

//   // data to pass to Tab component
//   const tabData = [
//     {
//       id: 1,
//       tabName: "Student",
//       type: ACCOUNT_TYPE.STUDENT,
//     },
//     {
//       id: 2,
//       tabName: "Instructor",
//       type: ACCOUNT_TYPE.INSTRUCTOR,
//     },
//   ];

//   return (
//     <div>
//       {/* Tab */}
//       <Tab tabData={tabData} field={accountType} setField={setAccountType} />
//       {/* Form */}
//       <form onSubmit={handleOnSubmit} className="flex w-full flex-col gap-y-4">
//         <div className="flex gap-x-4">
//           <label style={{ position: "relative" }}>
//             <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-black-5">
//               First Name <sup className="text-pink-200">*</sup>
//             </p>
//             <FaUser
//               style={{
//                 color: "black",
//                 position: "absolute",
//                 top: "calc(49%)",
//                 left: "2.85%",
//                 fontSize: "20px",
//               }}
//             />
//             <input
//               required
//               type="text"
//               name="firstName"
//               value={firstName}
//               onChange={handleOnChange}
//               placeholder="Enter first name"
//               style={{
//                 boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
//                 paddingLeft: "35px",
//               }}
//               className="w-full rounded-[0.5rem] bg-richblack-800 p-[12px] text-black-5"
//             />
//           </label>
//           <label style={{ position: "relative" }}>
//             <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-lack-5">
//               Last Name <sup className="text-pink-200">*</sup>
//             </p>
//             <FaUser
//               style={{
//                 color: "black",
//                 position: "absolute",
//                 top: "calc(49%)",
//                 left: "2.85%",
//                 fontSize: "20px",
//               }}
//             />
//             <input
//               required
//               type="text"
//               name="lastName"
//               value={lastName}
//               onChange={handleOnChange}
//               placeholder="Enter last name"
//               style={{
//                 boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
//                 paddingLeft: "35px",
//               }}
//               className="w-full rounded-[0.5rem] bg-black-800 p-[12px] text-black-5"
//             />
//           </label>
//         </div>
//         <label className="w-full" style={{ position: "relative" }}>
//           <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-black-5">
//             Email Address <sup className="text-pink-200">*</sup>
//           </p>
//           <SiGmail
//             style={{
//               color: "black",
//               position: "absolute",
//               top: "calc(53%)",
//               left: "1.85%",
//               fontSize: "20px",
//             }}
//           />
//           <input
//             required
//             type="text"
//             name="email"
//             value={email}
//             onChange={handleOnChange}
//             placeholder="Enter email address"
//             style={{
//               boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
//               paddingLeft: "35px",
//             }}
//             className="w-full rounded-[0.5rem] bg-black-800 p-[12px] text-black-5"
//           />
//         </label>
//         <div className="flex gap-x-4">
//           <label className="relative" style={{ position: "relative" }}>
//             <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-black-5">
//               Create Password <sup className="text-pink-200">*</sup>
//             </p>
//             <FaLock
//               style={{
//                 color: "black",
//                 position: "absolute",
//                 top: "calc(52%)",
//                 left: "4.85%",
//                 fontSize: "20px",
//               }}
//             />
//             <input
//               required
//               type={showPassword ? "text" : "password"}
//               name="password"
//               value={password}
//               onChange={handleOnChange}
//               placeholder="Enter Password"
//               style={{
//                 boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
//                 paddingLeft: "35px",
//               }}
//               className="w-full rounded-[0.5rem] bg-black-800 p-[12px] pr-10 text-black-5"
//             />
//             <span
//               onClick={() => setShowPassword((prev) => !prev)}
//               className="absolute right-3 top-[38px] z-[10] cursor-pointer"
//             >
//               {showPassword ? (
//                 <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
//               ) : (
//                 <AiOutlineEye fontSize={24} fill="#AFB2BF" />
//               )}
//             </span>
//           </label>
//           <label className="relative" style={{ position: "relative" }}>
//             <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-black-5">
//               Confirm Password <sup className="text-pink-200">*</sup>
//             </p>
//             <FaLock
//               style={{
//                 color: "black",
//                 position: "absolute",
//                 top: "calc(52%)",
//                 left: "4.85%",
//                 fontSize: "20px",
//               }}
//             />
//             <input
//               required
//               type={showConfirmPassword ? "text" : "password"}
//               name="confirmPassword"
//               value={confirmPassword}
//               onChange={handleOnChange}
//               placeholder="Confirm Password"
//               style={{
//                 boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
//                 paddingLeft: "35px",
//               }}
//               className="w-full rounded-[0.5rem] bg-black-800 p-[12px] pr-10 text-black-5"
//             />
//             <span
//               onClick={() => setShowConfirmPassword((prev) => !prev)}
//               className="absolute right-3 top-[38px] z-[10] cursor-pointer"
//             >
//               {showConfirmPassword ? (
//                 <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
//               ) : (
//                 <AiOutlineEye fontSize={24} fill="#AFB2BF" />
//               )}
//             </span>
//           </label>
//         </div>
//         <button
//           type="submit"
//           className="mt-6 rounded-[8px] bg-yellow-50 py-[8px] px-[12px] font-medium text-black-900"
//         >
//           Create Account
//         </button>
//       </form>
//     </div>
//   );
// }

// export default SignupForm;

// new changes

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { sendOtp } from "../../../services/operations/authAPI";
import { ACCOUNT_TYPE } from "../../../utils/constants";
import Tab from "../../common/Tab";
import { FaUser, FaLock } from "react-icons/fa";
import { SiGmail } from "react-icons/si";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function SignupForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [accountType, setAccountType] = useState(ACCOUNT_TYPE.STUDENT);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    contactNumber: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleOnChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      contactNumber: `+${value}`,
    }));
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    // Store signup data in Redux or localStorage
    const signupData = {
      accountType,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      password: formData.password,
      confirmPassword: formData.confirmPassword,
      contactNumber: formData.contactNumber,
    };

    // Store signup data in localStorage
    localStorage.setItem("signupData", JSON.stringify(signupData));

    // Dispatch sendOtp with navigation
    dispatch(sendOtp(formData.email, navigate));
  };

  const tabData = [
    {
      id: 1,
      tabName: "Student",
      type: ACCOUNT_TYPE.STUDENT,
    },
    {
      id: 2,
      tabName: "Instructor",
      type: ACCOUNT_TYPE.INSTRUCTOR,
    },
  ];

  return (
    <div>
      <Tab tabData={tabData} field={accountType} setField={setAccountType} />
      <form onSubmit={handleOnSubmit} className="flex w-full flex-col gap-y-4">
        <div className="flex gap-x-4">
          <label style={{ position: "relative" }}>
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-black-5">
              First Name <sup className="text-pink-200">*</sup>
            </p>
            <FaUser
              style={{
                color: "black",
                position: "absolute",
                top: "calc(49%)",
                left: "2.85%",
                fontSize: "20px",
              }}
            />
            <input
              required
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleOnChange}
              placeholder="Enter first name"
              style={{
                boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                paddingLeft: "35px",
              }}
              className="w-full rounded-[0.5rem] bg-richblack-800 p-[12px] text-black-5"
            />
          </label>
          <label style={{ position: "relative" }}>
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-black-5">
              Last Name <sup className="text-pink-200">*</sup>
            </p>
            <FaUser
              style={{
                color: "black",
                position: "absolute",
                top: "calc(49%)",
                left: "2.85%",
                fontSize: "20px",
              }}
            />
            <input
              required
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleOnChange}
              placeholder="Enter last name"
              style={{
                boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                paddingLeft: "35px",
              }}
              className="w-full rounded-[0.5rem] bg-black-800 p-[12px] text-black-5"
            />
          </label>
        </div>

        <label className="w-full" style={{ position: "relative" }}>
          <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-black-5">
            Email Address <sup className="text-pink-200">*</sup>
          </p>
          <SiGmail
            style={{
              color: "black",
              position: "absolute",
              top: "calc(53%)",
              left: "1.85%",
              fontSize: "20px",
            }}
          />
          <input
            required
            type="email"
            name="email"
            value={formData.email}
            onChange={handleOnChange}
            placeholder="Enter email address"
            style={{
              boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
              paddingLeft: "35px",
            }}
            className="w-full rounded-[0.5rem] bg-black-800 p-[12px] text-black-5"
          />
        </label>

        <label className="w-full">
          <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-black-5">
            Phone Number <sup className="text-pink-200">*</sup>
          </p>
          <PhoneInput
            country={"in"}
            value={formData.contactNumber.replace("+", "")}
            onChange={handlePhoneChange}
            inputClass="w-full bg-transparent p-[12px] text-black-5 outline-none"
          />
        </label>

        <div className="flex gap-x-4">
          <label style={{ position: "relative" }}>
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-black-5">
              Create Password <sup className="text-pink-200">*</sup>
            </p>
            <FaLock
              style={{
                color: "black",
                position: "absolute",
                top: "calc(52%)",
                left: "4.85%",
                fontSize: "20px",
              }}
            />
            <input
              required
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleOnChange}
              placeholder="Enter Password"
              style={{
                boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                paddingLeft: "35px",
              }}
              className="w-full rounded-[0.5rem] bg-black-800 p-[12px] pr-10 text-black-5"
            />
            <span
              onClick={() => setShowPassword((prev) => !prev)}
              className="absolute right-3 top-[38px] z-[10] cursor-pointer"
            >
              {showPassword ? (
                <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
              ) : (
                <AiOutlineEye fontSize={24} fill="#AFB2BF" />
              )}
            </span>
          </label>

          <label style={{ position: "relative" }}>
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-black-5">
              Confirm Password <sup className="text-pink-200">*</sup>
            </p>
            <FaLock
              style={{
                color: "black",
                position: "absolute",
                top: "calc(52%)",
                left: "4.85%",
                fontSize: "20px",
              }}
            />
            <input
              required
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleOnChange}
              placeholder="Confirm Password"
              style={{
                boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                paddingLeft: "35px",
              }}
              className="w-full rounded-[0.5rem] bg-black-800 p-[12px] pr-10 text-black-5"
            />
            <span
              onClick={() => setShowConfirmPassword((prev) => !prev)}
              className="absolute right-3 top-[38px] z-[10] cursor-pointer"
            >
              {showConfirmPassword ? (
                <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
              ) : (
                <AiOutlineEye fontSize={24} fill="#AFB2BF" />
              )}
            </span>
          </label>
        </div>

        <button
          type="submit"
          className="mt-6 rounded-[8px] bg-yellow-50 py-[8px] px-[12px] font-medium text-black-900"
        >
          Create Account
        </button>
      </form>
    </div>
  );
}

export default SignupForm;
